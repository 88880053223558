import UNumber from "./UNumber.mts";
import UTuple from "./UTuple.mts";

type CreateRange<
  Start extends number,
  End extends number,
  Count extends number[] = UTuple.Of<any, Start>,
> = Count["length"] extends End
  ? []
  : [Count["length"], ...CreateRange<Start, End, [...Count, any]>];

type AllowedSize = CreateRange<0, 47>[number] | 47;

type Range<Start extends number, End extends number> =
  UNumber.Subtract<End, Start> extends AllowedSize
    ? CreateRange<Start, End>
    : number[];

function Range<End extends number>(end: End): Range<0, End>;
function Range<Start extends number, End extends number>(
  start: Start,
  end: End,
): Range<Start, End>;
function Range(startOrEnd: number, maybeEnd?: number) {
  const start = maybeEnd == null ? 0 : startOrEnd;
  const end = maybeEnd == null ? startOrEnd : maybeEnd;

  return Array.from({ length: end - start }, (_, i) => i + start);
}

namespace Range {
  export const fromBounds = <const Bounds extends UNumber.Bounds>(
    bounds: Bounds,
  ) => Range(bounds[0] as Bounds[0], bounds[1] as Bounds[1]);
}

export default Range;
